.contacts-page {
  ol.breadcrumb {
    justify-content: center;
  }

  img.img-responsive {
    height: 12rem;
    width: 14rem;
    margin-top: 10px;
    max-height: 125px;
  }
}
