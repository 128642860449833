// Colors
$sand-orange: #f5b75f;
$navy-blue: #1e4d8a;
$dark-grey-1: #b4b4b4;
$teladoc-purple: #584b9f;
$teladoc-lightblue: #16b2e4;

// Font weights
$medium-heavy: 650;

// Screen size
// Small screen / tablet
$screen-sm: 768px;
// Medium screen / desktop
$screen-md: 992px;
// Large screen / wide desktop
$screen-lg: 1200px;
