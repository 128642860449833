.stocks-page {
  .table {
    margin-top: 1%;
  }

  .stock-header {
    margin: 0px -10px 0px -10px;
    padding: 60px;
    text-align: center;
    background: #1abc9c;
    color: white;
    font-size: 30px;
  }
}
