@import "../../shared/stylesheets/variables";

.experience-page {
  .media {
    display: flex;
  }
  .list-group-item {
    margin-top: 1.2rem;
    text-align: left;
    align-items: center;

    &.active {
      background: $teladoc-purple;
      border-color: $teladoc-purple;
    }
  }
  .panel {
    text-align: left;
  }

  img {
    height: 112px;
    width: 135px;
  }
}
