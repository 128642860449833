body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.contacts-page ol.breadcrumb {
  justify-content: center; }

.contacts-page img.img-responsive {
  height: 12rem;
  width: 14rem;
  margin-top: 10px;
  max-height: 125px; }

.home-content .home-header i:first-of-type {
  margin-left: 0.45rem; }

.home-content img {
  margin-top: 55%;
  min-width: 120px; }

.list-group p {
  margin-top: 9%; }

.list-group .list-group-item.active {
  background: #456fb2;
  color: #e6ebf4; }

.list-group .list-group-item {
  color: #304d7c; }
  .list-group .list-group-item i {
    margin-left: 2%;
    margin-right: 2%; }

@media only screen and (min-width: 1024px) {
  .home-page {
    margin-top: 2%; }
    .home-page .self-portrait {
      margin: 15px 0px 0px 0px !important; }
    .home-page .img-fluid {
      margin: 0px 0px 15px 25px;
      width: 350px; }
    .home-page .tech {
      margin-top: 20px; }
  .home-content {
    background-color: #bdc3c7;
    align-items: center; }
    .home-content .lead {
      margin: 2%;
      font-weight: 480; } }

footer ul.list-inline {
  margin-bottom: 6px; }

footer li.list-inline-item:last-child a {
  text-decoration: none; }

footer span:first-of-type {
  padding-bottom: 15px; }

.navbar {
  border-radius: 4.25%; }
  .navbar i:hover {
    color: #f5b75f; }
  .navbar .nav-link {
    font-weight: 650; }
    .navbar .nav-link:hover {
      background: #f5b75f; }

@media (max-width: 768px) {
  .navbar .float-right {
    display: flex;
    margin-left: 35%; } }

.news-page .media {
  display: flex; }

.news-page .list-group-item {
  margin-top: 1.2rem;
  text-align: left;
  align-items: center; }
  .news-page .list-group-item:last-child {
    margin-bottom: 1rem; }

.news-page img {
  height: 7rem;
  width: 8.3rem; }

.news-page .media-heading:hover {
  cursor: pointer; }

.news-page .view-more {
  cursor: pointer; }
  .news-page .view-more:hover {
    color: #666; }

@media screen and (max-width: 1200px) {
  .news-page img {
    height: 6.2rem;
    width: 5.1rem; } }

.main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(200px, auto);
  grid-gap: 2.6rem;
  gap: 2.6rem;
  margin: 0 1rem; }
  .main .resource-card {
    background: #efefef;
    border: 1px solid #b4b4b4;
    box-shadow: 2.2px 3.2px #ececec;
    border-radius: 5px; }
    .main .resource-card .resource-content {
      margin-bottom: 1rem; }
      .main .resource-card .resource-content ul {
        padding: 0;
        list-style: none; }
        .main .resource-card .resource-content ul li {
          margin-bottom: 0.175rem; }
          .main .resource-card .resource-content ul li:last-of-type {
            margin-bottom: 0; }
          .main .resource-card .resource-content ul li a {
            text-decoration: none; }

@media screen and (max-width: 768px) {
  .main {
    grid-template-columns: 1fr; }
    .main .resource-card:last-of-type {
      margin-bottom: 1rem; } }

.stocks-page .table {
  margin-top: 1%; }

.stocks-page .stock-header {
  margin: 0px -10px 0px -10px;
  padding: 60px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px; }

.row {
  justify-content: center; }

.fa-thermometer-half {
  margin-left: 10px; }

.experience-page .media {
  display: flex; }

.experience-page .list-group-item {
  margin-top: 1.2rem;
  text-align: left;
  align-items: center; }
  .experience-page .list-group-item.active {
    background: #584b9f;
    border-color: #584b9f; }

.experience-page .panel {
  text-align: left; }

.experience-page img {
  height: 112px;
  width: 135px; }

.App {
  display: flex;
  text-align: center;
  min-height: 100vh;
  flex-direction: column; }

.content {
  flex: 1 1 auto;
  margin: 0px 10px 10px 10px; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

